import {Component} from 'react';
import Fire from '../components/fire';
import '../styles/pages/landing.css';

export default class Landing extends Component {
    render() {
        return (
            <div>
                <div className="logoHeader">
                    <sc>Scorch</sc> To Go
                    <br/><br/>
                </div>
                <div className="bio">
                    For the best s'mores you've ever tasted, call or text <a href="tel:3109220016">310-922-0016</a> to place an order now.
                </div>
                <Fire />
            </div>
            
        )
    }
}